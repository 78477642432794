<template>
  <div class="home">
    
  </div>
</template>

<script>



export default {
  name: 'Home',
  components: {}
}
</script>
