<template>
  <div>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-toolbar-title class="ml-2 mt-2">
            Gerenciamento de Peças
          </v-toolbar-title>
        </v-col>

        <v-col
          class="colunaHeader"
          cols="12"
          sm="12"
          md="8"
          lg="8"
          xl="8"
          align="end"
        >
          <v-btn class="ml-2 my-1" color="primary" @click="dialog = true">
            <v-icon left>mdi-plus-thick</v-icon> Cadastrar uma Peça
          </v-btn>

          <v-btn class="ml-2 my-1" color="primary" @click="dialogoImportacaoDePeca = true">
            <v-icon left>mdi-adobe-acrobat</v-icon> Importação de peça
          </v-btn>

          <v-btn class="ml-2 my-1" color="primary" @click="exportar">
            <v-icon left>mdi-microsoft-excel</v-icon> Exportar
          </v-btn>
        </v-col>
      </v-row>
      <v-card class="mt-2 py-4 px-2" elevation="1">
        <v-row no-gutters>
          <v-col cols="12" sm="6" md="4" lg="3" xl="3">
            <v-text-field
              v-model="searchValor"
              label="Nome da Peça"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="12" sm="6" md="5" lg="3" xl="3" align="end">
            <v-btn class="ml-2 mb-1" color="primary" @click="limpar()">
              <v-icon left>mdi-filter-off</v-icon> Limpar
            </v-btn>
            <v-btn class="ml-2 mb-1" color="primary" @click="Filtrar(searchValor)">
              <v-icon left>mdi-filter</v-icon> Filtrar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="mt-2 py-4 px-2" elevation="1">
        <v-data-table
          :headers="headers"
          :items="pecas"
          item-key="id"
         
          multi-sort
          :items-per-page="itemsPerPage"
          class="elevation-0"
          :search="search"
          :sort-by="sortBy.toLowerCase()"
          :sort-desc="sortDesc"
          @page-count="changePages"
          @current-items="changeCurrentItens"
        >
          <template v-slot:[`item.imagem_3d`]="{ item }">
           <v-img
           :src="$arquivoProtegido(item.imagem_3d) || '/images/not-found.png'"
              lazy-src="/images/not-found.png"
              class="white lighten-5 mb-2"
              max-height="50"
              max-width="50"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </template>

          <template v-slot:[`item.imagem_positivacao`]="{ item }">
            <v-img
            :src="$arquivoProtegido(item.imagem_positivacao) || '/images/not-found.png'"
              lazy-src="/images/not-found.png"
              class="white lighten-5 mb-2 imgTable"
              max-height="50"
              max-width="50"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </template>

          <!-- ========
          Menu 
          ======= -->
          <template v-slot:[`item.acao`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" color="primary" v-bind="attrs" v-on="on">
                  mdi-menu
                </v-icon>
              </template>
              <v-card>
                <v-list dense nav>
                  <v-list-item
                    link
                    @click="dialogEditando = true;
                editando = { ...item };
                log();"
                  >
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-file-document-edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item                  
                    link
                    @click="Delete(item.id)"
                  >
                    <v-list-item-icon>
                      <v-icon color="error">mdi-close</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Excluir</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </template>

          <!-- ========
          Menu 
          ======= -->

         
        </v-data-table>

        <!-- ==================
        Formulário de Cadastro de Peça 
        ================= -->

        <v-dialog v-model="dialog" max-width="90%">
          <v-card>
            <v-card-title class="subtitle-1 cyan white--text">
              Nova Peça
            </v-card-title>
            <v-card-text class="mt-4"> </v-card-text>
            <v-divider></v-divider>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      outlined
                      dense
                      required
                      v-model="novoCadastro.nome"
                      label="Nome da Peça Admin"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      outlined
                      dense
                      required
                      v-model="novoCadastro.nomepecaapp"
                      label="Nome da Peça APP"
                    ></v-text-field>
                  </v-col>
                 </v-row> 
                  <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-file-input
                      outlined
                      dense
                      required
                      prepend-icon="mdi-file-image"
                      accept="image/*"
                      counter
                      clearable
                      small-chips
                      label="Imagem 3D"
                      v-model="novoCadastro.imagem3D"
                      @change="changeImagem3D"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" sm="8" md="8">
                    <v-img
                      :src="
                        $arquivoProtegido(novoCadastro.imagem3D_URL) || '/images/not-found.png'
                      "
                      lazy-src="/images/not-found.png"
                      class="white lighten-5 mb-2"
                      max-height="50"
                      max-width="50"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-file-input
                      outlined
                      dense
                      required
                      prepend-icon="mdi-file-image"
                      accept="image/*"
                      counter
                      clearable
                      small-chips
                      label="Imagem Positivação"
                      v-model="novoCadastro.imagemPositivacao"
                      @change="changeImagemPositivacao"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" sm="8" md="8">
                    <v-img
                      :src="
                        $arquivoProtegido(novoCadastro.imagemPositivacao_URL) ||
                        '/images/not-found.png'
                      "
                      lazy-src="/images/not-found.png"
                      class="white lighten-5 mb-2"
                      max-height="50"
                      max-width="50"
                    ></v-img>
                  </v-col>                  
                </v-row>
                <v-row>
                   <v-col cols="12" sm="8" md="8">
                    <v-text-field
                      outlined
                      dense
                      required
                      v-model="novoCadastro.observacao"
                      label="Observação"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="close">
                <v-icon>mdi-close</v-icon> Cancelar</v-btn
              >
              <v-btn class="cyan" @click="CriarNovo(novoCadastro)">
                <v-icon>mdi-chevron-right</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- =============================
              DIAGOLO PARA EDIÇÃO DOS DADOS
              =========================== -->
        <v-dialog v-model="dialogEditando" max-width="90%">
          <v-card>
            <v-card-title class="subtitle-1 cyan white--text">
              Editando Peça {{ editando.nome }}
            </v-card-title>
            <v-card-text class="mt-4"> </v-card-text>
            <v-divider></v-divider>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      outlined
                      dense
                       
                      required
                      v-model="editando.observacao"
                      label="Observação"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-file-input
                      outlined
                      dense
                      
                      required
                      prepend-icon="mdi-file-image"
                      accept="image/*"
                      counter
                      clearable
                      small-chips
                      label="Imagem 3D"
                      v-model="editando.imagem3D"
                      @change="changeImagem3D"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" sm="8" md="8">
                    <v-img
                      :src="$arquivoProtegido(editando.imagem_3d) || '/images/not-found.png'"
                      lazy-src="/images/not-found.png"
                      class="white lighten-5 mb-2"
                      max-height="50"
                      max-width="50"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-file-input
                      outlined
                      dense
                      
                      required
                      prepend-icon="mdi-file-image"
                      accept="image/*"
                      counter
                      clearable
                      small-chips
                      label="Imagem Positivação"
                      v-model="editando.imagemPositivacao"
                      @change="changeImagemPositivacao"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" sm="8" md="8">
                    <v-img
                      :src="
                        $arquivoProtegido(editando.imagem_positivacao) || '/images/not-found.png'
                      "
                      lazy-src="/images/not-found.png"
                      class="white lighten-5 mb-2"
                      max-height="50"
                      max-width="50"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="dialogEditando = false">
                <v-icon>mdi-close</v-icon>Cancelar</v-btn
              >
              <v-btn class="cyan" @click="Update(editando)"
                ><v-icon>mdi-chevron-right</v-icon>Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>


  <div v-if="Step == 1">
        <ImportacaoDePeca
          ref="modal"
          :dialogoImportacaoDePeca="dialogoImportacaoDePeca"
          @sairmodal="sairdialogoImportacaoDePeca"
        />

  </div>
 <div v-if="Step == 2">
     
     <ValidacaoImportacaoDePeca
          ref="modal"
          :buscar="buscarpecas"
          :dialogoImportacaoDePeca="dialogoImportacaoDePeca"
          @sairmodal="sairdialogoImportacaoDePeca"
        />
  
  </div>

      


      </v-card>
    </v-container>


      <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="snackbar">
      {{snackbarText}}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>


  </div>
</template>


<script>
import axios from "axios";
import XLSX from "xlsx";


import ImportacaoDePeca from "./ImportacaoDePeca.vue";
import ValidacaoImportacaoDePeca from "./ValidacaoImportacaoDePeca.vue"

export default {
  components: { ImportacaoDePeca,ValidacaoImportacaoDePeca },
  computed:{
    Step(){
  
      return this.$store.state.dialogostep.step
    }

  },

  data: () => ({
    dialogoImportacaoDePeca: false,
    headers: [
     { text: "Ação", value: "acao", align: "center" },
      { text: "Nome peça Admin", value: "nome", sortable: false, align: "center" },
      { text: "Nome peça App", value: "nomepecaapp", sortable: false, align: "center" },
      { text: "Imagem 3d", value: "imagem_3d", align: "center" },
      {
        text: "imagem Positivacao",
        value: "imagem_positivacao",
        align: "center",
      },
      { text: "Observação", value: "observacao", align: "center" },
     
    ],
    searchValor: "",
    teste: [],
    dialog: false,
    novoCadastro: {},
    overlay: true,
    pecas: [],
    itemsPerPageArray: [1, 5, 10, 15],
    search: null,
    select: "",
    filter: {},
    sortDesc: false,
    page: 1,
    itemsPerPage: 10,
    sortBy: "nome",
    numberOfPages: 1,
    items: [],
    snackbar: false,
    snackbarText: "",
    editando: {},
    dialogEditando: false,
  }),
 
   methods: {
    arquivo(file){
      console.log(file)
      return file
    },
 
  async buscarpecas(){
  
      
      this.pecas = (
          await axios.get(`${this.$SERVER_URL}adm/peca`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
            },
          })
        ).data;

     
        this.$store.commit("importacaoPecas/pecasJaCadastradas", {
          pecas: this.pecas,
        });
        this.querySelections(this.select);
        this.overlay = false;
     },

    sairdialogoImportacaoDePeca(data) {
      this.dialogoImportacaoDePeca = data;
    
    },
    limpar() {
      this.search = "";
      this.searchValor = "";
    },
    Filtrar(searchValor) {
    
      this.search = searchValor;
    },
    log() {
   
    },
    async CriarNovo(novoCadastro) {

    try {
      this.dialog = false;
          const regexAll = /[^\\]*\.(\w+)$/;
          const data = {
            nome: novoCadastro.nome,
            nomepecaapp: novoCadastro.nomepecaapp,
            observacao: novoCadastro.observacao,
          };

          if (this.pecas.find((val) => val.nome == novoCadastro.nome)) {
            this.snackbarText = "Peça já cadastrada";
            this.snackbar = true;
            return;
          }

          if (novoCadastro.imagemPositivacao instanceof File) {
            const link = (
              await axios.post(
                `${this.$SERVER_URL}adm/sync/images`,
                {
                  fileTypes: [
                    novoCadastro.imagemPositivacao.name.match(regexAll)[1],
                  ],
                },
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods":
                      "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                      "Origin, Content-Type, X-Auth-Token",
                  },
                }
              )
            ).data;
        
            axios.put(link[0].uploadUrl, novoCadastro.imagemPositivacao, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Origin, Content-Type, X-Auth-Token",
                "Content-Type": novoCadastro.imagemPositivacao.type,
              },
            });
            data.imagem_positivacao = link[0].downloadUrl;
          }
          if (novoCadastro.imagem3D instanceof File) {
            const link = (
              await axios.post(
                `${this.$SERVER_URL}adm/sync/images`,
                {
                  fileTypes: [novoCadastro.imagem3D.name.match(regexAll)[1]],
                },
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods":
                      "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                      "Origin, Content-Type, X-Auth-Token",
                  },
                }
              )
            ).data;
            axios.put(link[0].uploadUrl, novoCadastro.imagem3D, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Origin, Content-Type, X-Auth-Token",
                "Content-Type": novoCadastro.imagem3D.type,
              },
            });
            data.imagem_3d = link[0].downloadUrl;
          }

          this.overlay = true;


          this.pecas = (
            await axios.post(`${this.$SERVER_URL}adm/peca/${localStorage.getItem('userid')}`, data, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Origin, Content-Type, X-Auth-Token",
                "Content-Type": "application/json",
              },
            })
          ).data;
          this.novoCadastro = {}
          this.overlay = false;
          this.snackbarText = "Cadastrado com sucesso!";
          this.snackbar = true;
    } catch (error) {
          this.overlay = false;
          this.snackbarText = "Algum erro aconteceu, tente novamente";
          this.snackbar = true;
    }
 
     
    },
    async Delete(id) {
      try {
      this.dialog = false;
      this.overlay = true;
     
      this.pecas = (
        await axios.delete(`${this.$SERVER_URL}adm/peca/${id}/${localStorage.getItem('userid')}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
      ).data;
      this.overlay = false;
      this.snackbarText = "Peça deletada!";
      this.snackbar = true;
      } catch (error) {
            this.overlay = false;
          this.snackbarText = "Algum erro aconteceu, tente novamente";
        this.snackbar = true;
      }
 
    },
    async Update(editando) {
      try {
        const regexAll = /[^\\]*\.(\w+)$/;
      this.dialogEditando = false;
      this.overlay = true;
      const data = { nome: editando.nome, observacao: editando.observacao };
      if (editando.imagemPositivacao instanceof File) {
        const link = (
          await axios.post(
            `${this.$SERVER_URL}adm/sync/images`,
            {
              fileTypes: [editando.imagemPositivacao.name.match(regexAll)[1]],
            },
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Origin, Content-Type, X-Auth-Token",
              },
            }
          )
        ).data;
        axios.put(link[0].uploadUrl, editando.imagemPositivacao, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
            "Content-Type": editando.imagemPositivacao.type,
          },
        });
        data.imagem_positivacao = link[0].downloadUrl;
      }
      if (editando.imagem3D instanceof File) {
        const link = (
          await axios.post(
            `${this.$SERVER_URL}adm/sync/images`,
            {
              fileTypes: [editando.imagem3D.name.match(regexAll)[1]],
            },
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Origin, Content-Type, X-Auth-Token",
              },
            }
          )
        ).data;
        axios.put(link[0].uploadUrl, editando.imagem3D, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
            "Content-Type": editando.imagem3D.type,
          },
        });
        data.imagem_3d = link[0].downloadUrl;
      }
      this.pecas = (
        await axios.put(`${this.$SERVER_URL}adm/peca/${editando.id}/${localStorage.getItem('userid')}`, data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
            "Content-Type": "application/json",
          },
        })
      ).data;
      this.overlay = false;
      this.snackbarText = "Peça atualizada!";
      this.snackbar = true;
      } catch (error) {
      this.overlay = false;
      this.snackbarText = "Algum erro aconteceu,tente novamente";
      this.snackbar = true;
      }
      
    },
    close() {
      this.dialog = false;
    },
    changeCurrentItens(value) {
      this.teste = value;
    },
    exportar() {
      const prevItemsPerPage = this.itemsPerPage;
      this.itemsPerPage = Infinity;
      this.$nextTick().then(() => {
        const data = this.teste.map((val) => ({
          "Nome da Peça Admin": val.nome,
          "Nome da Peça App": val.nomepecaapp,
          Observação: val.observacao,
          "Imagem 3D": {
            f: `HYPERLINK("${val.imagem_3d && this.$assinarUrl(val.imagem_3d)}","Imagem 3D ${val.nome}")`,
          },
          "Imagem Positivação": {
            f: `HYPERLINK("${val.imagem_positivacao && this.$assinarUrl(val.imagem_positivacao)}","Imagem 3D ${val.nome}")`,
          },
        }));
        this.itemsPerPage = prevItemsPerPage;
        const ws = XLSX.utils.json_to_sheet(data);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Lista de Peças");
        XLSX.writeFile(wb, `lista_de_peças_${new Date().getTime()}.xlsx`);
      });
    },
    async changeImagem3D(file) {
      if (file instanceof File)
        this.novoCadastro.imagem3D_URL = await this.getImageFromFile(file);
      this.novoCadastro = { ...this.novoCadastro };
    },
    async changeImagemPositivacao(file) {
      if (file instanceof File)
        this.novoCadastro.imagemPositivacao_URL = await this.getImageFromFile(
          file
        );
      this.novoCadastro = { ...this.novoCadastro };
    },
    async getImageFromFile(file) {
      return new Promise((res) => {
        const fr = new FileReader();

        fr.onload = function () {
          res(fr.result);
        };
        fr.readAsDataURL(file);
      });
    },
    changePages(val) {
      this.numberOfPages = val;
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    querySelections(v) {
      this.items = [
        ...this.pecas
          .map((val) => val.nome)
          .filter((e) => {
            return (
              (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
            );
          }),
        v,
      ];
    },
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
      this.select = val;
    },
  },
  async mounted() {
   await this.buscarpecas();
  },
};
</script>

<style  >
.colunaHeader > .cyan {
  color: #fff;
  background-color: #6ababa;
}

.theme--light.v-btn.cyan {
  color: #fff;
}
</style>